<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Banks</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/banking/new-bank">
            <v-icon left dark>mdi-plus</v-icon>New Bank
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="banks"
                :search="search"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    banks: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Country", value: "country.name" },
      { text: "Bank Name", value: "BankName" },
      { text: "Account", value: "DfltAcct" }
    ]
  }),
  methods: {
    getBanks() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bank`)
        .then(res => {
          self.banks = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getBanks();
  }
};
</script>